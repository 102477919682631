<template>
    <div>
      <cta-banner :title="$route.name"/>    
      
      <section class="container mx-auto pt-20">
        <div class="w-full pb-12" v-if="talleres">
          <h2 class="text-3xl font-bold mb-7 title relative">{{ talleres.titulo }}</h2>
          <div v-html="talleres.cuerpo" style="white-space: pre-wrap" align="justify"></div>
        </div>

        <div class="w-full pb-12" v-if="charlas">
          <h2 class="text-3xl font-bold mb-7 title relative">{{ charlas.titulo }}</h2>
          <div v-html="charlas.cuerpo" style="white-space: pre-wrap" align="justify"></div>
        </div>

      </section>
      
    </div>
  </template>
  
  <script>
  import API from "@/api";
  import CtaBanner from '../../components/common/CtaBanner.vue';
  import ArrowCollapseDown from 'vue-material-design-icons/ArrowCollapseDown.vue';
  import FileDocumentOutline from 'vue-material-design-icons/FileDocumentOutline.vue';
  import {mapState} from "vuex";
  import pdf from 'vue-pdf';

  
  export default {
    name: "Programación",
    components: {
      CtaBanner,
      ArrowCollapseDown,
      FileDocumentOutline,
      pdf,
    },
    data() {
      return {
        talleres: {},
        charlas: {},
      };
    },
    computed: {
      ...mapState(["idEventoActual"]),
    },
    async created() {
      this.getInfo();
    },
    methods: { 
      async getInfo(){
        const params = {
          "evento_fk" : this.idEventoActual,
          format:  "json"
        };
        const { data } = await API.getInfo(params);
        let items = data.results;
        for (let i in items){
          this.talleres = (items[i].codigo === 'PO01') ? items[i] : this.talleres;
          this.charlas = (items[i].codigo === 'PO02') ? items[i] : this.charlas;
        }
      },
    }
  }
  </script>
  
  <style>
    .color_a {
        color: #0d6efd;
    }
  </style>
  